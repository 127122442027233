// TODO: We could move these in the component and add the translation strings.
// TEACHER PORTAL
export const MY_SCHOOL_NEW_REQUESTS = [
  getColumnDataItem('email', 'E-mailadres'),
  getColumnDataItem('name', 'Naam'),
  getColumnDataItem('date', 'Datum'),
  getColumnDataItem('actions', 'Acties'),
]

export const MY_SCHOOL_VERIFIED_COLLEAGUES = [
  getColumnDataItem('email', 'E-mailadres'),
  getColumnDataItem('name', 'Naam'),
  getColumnDataItem('date', 'Datum'),
  //getColumnDataItem('actions', 'Actie'),
]

export const MY_CLASSES_OPTIONS = [
  getColumnDataItem('name', 'Naam'),
  getColumnDataItem('teacher', 'Leraar'),
  getColumnDataItem('group', 'Groep'),
  getColumnDataItem('actions', 'Actie'),
]

export const MY_CLASSES_OPTIONS_UNAPPROVED = [
  getColumnDataItem('name', 'Naam'),
  getColumnDataItem('teacher', 'Leraar'),
  getColumnDataItem('group', 'Groep'),
]

export const MISSIONS = [
  getColumnDataItem('title', 'Titel'),
  getColumnDataItem('category', 'Categorie'),
  getColumnDataItem('gameDifficultyId', 'Niveau', true),
  getColumnDataItem('slideCount', 'Aantal dia\'s', true),
  getColumnDataItem('createdAt', 'Aangemaakt', true),
  getColumnDataItem('status', 'Status'),
  getColumnDataItem('actions', 'Acties')
]

export const MISSION_VIDEOS = [
  getColumnDataItem('title', 'Titel'),
  getColumnDataItem('url', 'Vimeo URL'),
  getColumnDataItem('actions', 'Acties')
]


export const MISSION_SLIDES =
[
  getColumnDataItem('slideNumber', 'Dia nummer'),
  getColumnDataItem('title', 'Titel'),
  getColumnDataItem('type', 'Type'),
  getColumnDataItem('actions', 'Acties')
]

export const TAGS = [
  getColumnDataItem('name', 'Tag'),
  getColumnDataItem('actions', 'Acties')
]

export const MEDIAQUIZ = [
  getColumnDataItem('question', 'Vraag'),
  getColumnDataItem('gameDifficultyId', 'Niveau', true),
  getColumnDataItem('createdAt', 'Aangemaakt', true),  
  getColumnDataItem('status', 'Status'),
  getColumnDataItem('actions', 'Acties')
]

export const INSTRUCTIONS = [
  getColumnDataItem('order', '#'),
  getColumnDataItem('title', 'Titel'),
  getColumnDataItem('createdAt', 'Aangemaakt', true),
  getColumnDataItem('status', 'Status'),
  getColumnDataItem('actions', 'Acties')
]

export const VIDEO_AND_ATTACHMENTS = [
  getColumnDataItem('order', '#'),
  getColumnDataItem('name', 'Bestandsnaam'),
  getColumnDataItem('size', 'Grootte'),
  getColumnDataItem('createdAt', 'Toegevoegd', true),
  getColumnDataItem('actions', 'Acties')
]

export const EXTRA_GAMES = [
  getColumnDataItem('order', '#'),
  getColumnDataItem('title', 'Titel'),
  getColumnDataItem('partnerLink', 'Partnerlink'),
  getColumnDataItem('bits', 'Bits'),
  getColumnDataItem('difficulties', 'Groep'),
  getColumnDataItem('actions', 'Acties')
]

export const FAQ_TABLE = [
  getColumnDataItem('question', 'Titel', true),
  getColumnDataItem('faqCategoryId', 'Categorie', true),
  getColumnDataItem('createdAt', 'Aangemaakt', true),
  getColumnDataItem('isActivated', 'Status', true),
  getColumnDataItem('actions', 'Acties')
]

export const PRIZES_TABLE = [
  getColumnDataItem('order', '#'),
  getColumnDataItem('photo', 'Omslagfoto'),
  getColumnDataItem('title', 'Titel', true),
  getColumnDataItem('createdAt', 'Aangemaakt', true),
  getColumnDataItem('actions', 'Acties')
]

// HELPDESK
export const SCHOOLS_TABLE = [
  getColumnDataItem('name', 'Naam', true),
  getColumnDataItem('brin', 'BRIN', true),
  getColumnDataItem('postalCode', 'Postcode', true),
  getColumnDataItem('city', 'Stad', true),
  getColumnDataItem('province', 'Provincie', true),
  getColumnDataItem('createdAt', 'Aangemaakt', true),
  getColumnDataItem('updatedAt', 'Gewijzigd', true),
  getColumnDataItem('actions', 'Acties'),
]

export const LINKED_CLASSES = [
  getColumnDataItem('name', 'Naam'),
  getColumnDataItem('group', 'Groep'),
  getColumnDataItem('totalStudents', 'Aantal leerlingen'),
  getColumnDataItem('actions', 'Acties'),
]

export const LINKED_TEACHERS = [
  getColumnDataItem('name', 'Naam'),
  getColumnDataItem('actions', 'Acties'),
]

export const LINKED_MISSIONS = [
  getColumnDataItem('order', 'Nummer'),
  getColumnDataItem('name', 'Naam missie'),
  getColumnDataItem('started', 'Gestart'),
  getColumnDataItem('completed', 'Voltooid'),
  getColumnDataItem('bits', 'Bits verdiend'),
  getColumnDataItem('actions', 'Acties'),
]

export const LINKED_QUESTIONS = [
  getColumnDataItem('id', 'ID', true),
  getColumnDataItem('missionName', 'Naam Missie', false),
  getColumnDataItem('title', 'Titel', true),
  getColumnDataItem('name', 'Vraag', true),
  getColumnDataItem('time', 'Tijd benodigd', true),
  getColumnDataItem('bits', 'Bits verdiend', true),
  getColumnDataItem('actions', 'Acties', true),
]

export const LINKED_EXTRA_GAMES = [
  getColumnDataItem('id', 'ID', true),
  getColumnDataItem('title', 'Gespeelde Game', true),
  getColumnDataItem('count', 'Aantal keer gespeeld', true),
  getColumnDataItem('time', 'Tijd benodigd', true),
  getColumnDataItem('bits', 'Bits verdiend', true),
  getColumnDataItem('actions', 'Acties', true),
]

export const OTHER_CLASSES = [
  getColumnDataItem('name', 'Naam'),
  getColumnDataItem('actions', 'Acties'),
]

export const CLASSES_TABLE = [
  getColumnDataItem('name', 'Naam', true),
  getColumnDataItem('schoolTypeName', 'Type onderwijs'),
  getColumnDataItem('schoolClassTypeName', 'Competitie'),
  getColumnDataItem('studentCount', 'Aantal leerlingen', true),
  getColumnDataItem('createdAt', 'Aangemaakt', true),
  getColumnDataItem('actions', 'Acties'),
]


export const TEACHERS_TABLE =
[
  getColumnDataItem('selected', ' ', false),
  getColumnDataItem('name', 'Naam', true),
  getColumnDataItem('email', 'E-mailadres', true),
  getColumnDataItem('schoolTypeName', 'Type onderwijs'),
  getColumnDataItem('accountState', 'Account status'),
  getColumnDataItem('createdAt', 'Aangemaakt', true),
  getColumnDataItem('lastLogin', 'Laatst ingelogd', true),
  getColumnDataItem('actions', 'Acties'),
]

export const PROFESSIONALS_TABLE =
[
  getColumnDataItem('name', 'Naam', true),
  getColumnDataItem('email', 'E-mailadres', true),
  getColumnDataItem('accountState', 'Account status'),
  getColumnDataItem('createdAt', 'Aangemaakt', true),
  getColumnDataItem('lastLogin', 'Laatst ingelogd', true),
  getColumnDataItem('actions', 'Acties'),
]

export const RANKING_TABLE = [
  getColumnDataItem('position', 'Positie', false),
  getColumnDataItem('positionWithinFilter', 'Positie met filter', false),
  getColumnDataItem('name', 'Naam klas'),
  getColumnDataItem('schoolName', 'School'),
  getColumnDataItem('schoolTypeId', 'Type onderwijs'),
  getColumnDataItem('bitsEarned', 'Aantal bits'),
  getColumnDataItem('rank', 'Rank'),
  getColumnDataItem('actions', 'Acties')
]

export function formatDifficulties(difficulties, concatenator = ", ") {
  let difficultyArr = [];
  if (difficulties.includes(1)) {
    difficultyArr.push("5/6")
  }
  if (difficulties.includes(2)) {
    difficultyArr.push("7/8")
  }
  return difficultyArr.join(concatenator)
}

export function getTableOptions(columnData) {
  const columns = columnData.map(item => item.column)
  const headerRows = columns
  const bodyRows = columns
  const headings = getHeadings(columnData)
  const sortable = columnData.filter(item => item.sortable).map(item => item.column)

  return {
    columns,
    headerRows,
    bodyRows,
    headings,
    sortable,
  }
}

function getHeadings(columnData) {
  const headings = {}

  columnData.map(item => {
    headings[item.column] = item.name
  })

  return headings
}

function getColumnDataItem(column, name, sortable = false) {
  return {
    column: column,
    name: name,
    sortable: sortable
  }
}